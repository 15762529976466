import { unregisterIcons, registerIcons } from "@fluentui/react/lib/Styling";

import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
// Note: This approach works with any SVG icon set, not just @fluentui/react-icons-mdl2
import {
  BoxAdditionSolidIcon, ContactIcon, DeleteIcon, DeleteRowsIcon, HomeSolidIcon, InfoIcon, InsertRowsBelowIcon, RefreshIcon,
  InfoSolidIcon, UnknownSolidIcon, WarningSolidIcon, LockSolidIcon, NavigateBackIcon, ClearFilterIcon, DoubleChevronUpIcon,
  DoubleChevronDownIcon, SearchDataIcon, AddIcon, HomeIcon, ReportDocumentIcon, TaskManagerIcon, TaskSolidIcon, TaskboardIcon,
  PageIcon, PageEditIcon, CustomListIcon, IssueTrackingIcon, StatusCircleErrorXIcon, ClearIcon, UserRemoveIcon, SettingsIcon,
  SaveIcon, CancelIcon, CirclePlusIcon, PermissionsIcon, EditSolid12Icon, ViewIcon, AddFriendIcon, TextDocumentIcon, SendIcon,
  CircleAdditionSolidIcon, GlobalNavButtonIcon, SortIcon, FilterIcon
} from "@fluentui/react-icons-mdl2";

import { initializeIcons } from '@fluentui/font-icons-mdl2';

initializeIcons();

initializeFileTypeIcons();
//Note: For any icon name that has an icon already registered to it, you need to unregister it first before registering a new one
// unregisterIcons([
//   "HomeSolid", "Delete", "Contact", "InsertRowsBelow", "WarningSolid", "DeleteRows", "Refresh", "UnknownSolid", "LockSolid",
//   "InfoSolid", "Info", "BoxAdditionSolid", "NavigateBack", "ClearFilter", "DoubleChevronUp", "DoubleChevronDown", "SearchData",
//   "Add", "Home", "ReportDocument", "TaskManager", "TaskSolid", "Taskboard", "Page", "PageEdit", "CustomList", "IssueTracking",
//   "StatusCircleErrorX", "Clear", "UserRemove", "Settings", "Save", "Cancel", "CirclePlus", "Permissions", "EditSolid12", "View",
//   "AddFriend", "TextDocument", "Send", "CircleAdditionSolid", "GlobalNavButton", "Sort", "Filter"
// ]);

// registerIcons({
//   icons: {
//     HomeSolid: <HomeSolidIcon />,
//     Delete: <DeleteIcon />,
//     Contact: <ContactIcon />,
//     InsertRowsBelow: <InsertRowsBelowIcon />,
//     WarningSolid: <WarningSolidIcon />,
//     DeleteRows: <DeleteRowsIcon />,
//     Info: <InfoIcon />,
//     InfoSolid: <InfoSolidIcon />,
//     BoxAdditionSolid: <BoxAdditionSolidIcon />,
//     Refresh: <RefreshIcon />,
//     UnknownSolid: <UnknownSolidIcon />,
//     LockSolid: <LockSolidIcon />,
//     NavigateBack: <NavigateBackIcon />,
//     ClearFilter: <ClearFilterIcon />,
//     DoubleChevronUp: <DoubleChevronUpIcon />,
//     DoubleChevronDown: <DoubleChevronDownIcon />,
//     SearchData: <SearchDataIcon />,
//     Add: <AddIcon />,
//     Home: <HomeIcon />,
//     ReportDocument: <ReportDocumentIcon />,
//     TaskManager: <TaskManagerIcon />,
//     TaskSolid: <TaskSolidIcon />,
//     Taskboard: <TaskboardIcon />,
//     Page: <PageIcon />,
//     PageEdit: <PageEditIcon />,
//     CustomList: <CustomListIcon />,
//     IssueTracking: <IssueTrackingIcon />,
//     StatusCircleErrorX: <StatusCircleErrorXIcon />,
//     Clear: <ClearIcon />,
//     UserRemove: <UserRemoveIcon />,
//     Settings: <SettingsIcon />,
//     Save: <SaveIcon />,
//     Cancel: <CancelIcon />,
//     CirclePlus: <CirclePlusIcon />,
//     Permissions: <PermissionsIcon />,
//     EditSolid12: <EditSolid12Icon />,
//     View: <ViewIcon />,
//     AddFriend: <AddFriendIcon />,
//     TextDocument: <TextDocumentIcon />,
//     Send: <SendIcon />,
//     CircleAdditionSolid: <CircleAdditionSolidIcon />,
//     GlobalNavButton: <GlobalNavButtonIcon />,
//     Sort: <SortIcon />,
//     Filter: <FilterIcon />
//   },
// });
